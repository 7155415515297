import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import '../pdfWorker'; // Import the custom worker
import './PdfViewer.css';

function PdfViewer() {
  const [numPages, setNumPages] = useState(null);
  const [pageWidth, setPageWidth] = useState(window.innerWidth * 0.9);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth * 0.9);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="pdf-container">
      <Document
        file="./assets/urProg2024.pdf"  // Reference the local PDF file
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page 
            key={`page_${index + 1}`} 
            pageNumber={index + 1} 
            width={pageWidth} 
            renderTextLayer={false} 
            renderAnnotationLayer={false} 
          />
        ))}
      </Document>
    </div>
  );
}

export default PdfViewer;
