import React from 'react';
import './Schedule.css';

function Schedule() {
  const schedule = [
    { description: 'Start', venue: 'Newry', roadClosingTime: '-', firstCarDueTime: '08:30' },
    { description: 'SS 1', venue: 'Shinn Bridge 1', roadClosingTime: '07:25', firstCarDueTime: '08:50' },
    { description: 'SS 2', venue: 'Banbridge North 1', roadClosingTime: '08:10', firstCarDueTime: '09:35' },
    { description: 'Service A', venue: 'Newry', roadClosingTime: '-', firstCarDueTime: '10:25', isService: true },
    { description: 'SS 3', venue: 'Kilrea Hill 1', roadClosingTime: '09:30', firstCarDueTime: '11:00' },
    { description: 'SS 4', venue: "Tyrone's Ditches 1", roadClosingTime: '09:30', firstCarDueTime: '11:15' },
    { description: 'SS 5', venue: 'Babylon Hill 1', roadClosingTime: '10:10', firstCarDueTime: '11:40' },
    { description: 'Service B', venue: 'Newry', roadClosingTime: '-', firstCarDueTime: '12:30', isService: true },
    { description: 'SS 6', venue: 'Shinn Bridge 2', roadClosingTime: '-', firstCarDueTime: '13:25' },
    { description: 'Regroup', venue: 'Banbridge Civic Building', roadClosingTime: '-', firstCarDueTime: '14:00', isService: true },
    { description: 'SS 7', venue: 'Banbridge North 2', roadClosingTime: '-', firstCarDueTime: '14:25' },
    { description: 'Service C', venue: 'Newry', roadClosingTime: '-', firstCarDueTime: '15:10', isService: true },
    { description: 'SS 8', venue: 'Kilrea Hill 2', roadClosingTime: '-', firstCarDueTime: '15:45' },
    { description: 'SS 9', venue: "Tyrone's Ditches 2", roadClosingTime: '-', firstCarDueTime: '16:05' },
    { description: 'SS 10', venue: 'Babylon Hill 2', roadClosingTime: '-', firstCarDueTime: '16:30' },
    { description: 'Service D', venue: 'Newry', roadClosingTime: '-', firstCarDueTime: '17:20', isService: true },
    { description: 'Finish Ramp', venue: 'Newry', roadClosingTime: '-', firstCarDueTime: '17:30' },
  ];

  return (
    <div>
      <div className="container">
        <img src={`${process.env.PUBLIC_URL}/assets/bannerLarge.jpg`} alt="Modern Tyres Ulster Rally Logo" className="example-image" />
        <h1>Event Schedule</h1>
        <table className="schedule-table">
          <thead>
            <tr>
              <th>Description</th>
              <th>Venue</th>
              <th>Road Closed</th>
              <th>First Car</th>
            </tr>
          </thead>
          <tbody>
            {schedule.map((item, index) => (
              <tr key={index} className={item.isService ? 'service-row' : ''}>
                <td>{item.description}</td>
                <td>{item.venue}</td>
                <td>{item.roadClosingTime}</td>
                <td>{item.firstCarDueTime}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Schedule;
