import React from 'react';
import MapIcon from '@mui/icons-material/Map';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ListAltIcon from '@mui/icons-material/ListAlt';

function Home() {
  return (
    <div>
      <div className="container">
        <img src={`${process.env.PUBLIC_URL}/assets/bannerLarge.jpg`} alt="Modern Tyres Ulster Rally Logo" className="example-image" />
        <h1>Modern Tyres Ulster Rally 2024</h1>
        <p>17th August 2024</p>
        <ul>
          <li><MapIcon className="icon" /> Stage maps available for the Modern Tyres Ulster Rally.</li>
          <li><MenuBookIcon className="icon" /> Find the programme details for the rally.</li>
          <li><ListAltIcon className="icon" /> Check out the entry list for the event.</li>
        </ul>
        <p>Tap below to get started!</p>
        <img src={`${process.env.PUBLIC_URL}/assets/arrow.png`} alt="Arrow pointing down" className="arrow-image" />
      </div>
    </div>
  );
}

export default Home;