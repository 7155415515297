import React, { useState } from 'react';
import BottomNav from './components/BottomNav';
import Home from './components/Home';
import MapView from './components/MapView';
import Web from './components/Web';
import Schedule from './components/Schedule';
import PdfViewer from './components/PdfViewer';
import './App.css';

function App() {
  const [value, setValue] = useState('home');

  return (
    <div className="app-wrapper">
      <div className="app-container">
        <div className="content">
          {value === 'home' && <Home />}
          {value === 'map' && <MapView />}
          {value === 'pdf' && <PdfViewer />}
          {value === 'schedule' && <Schedule />}        
          {value === 'web' && <Web />}
        </div>
      </div>
      <BottomNav value={value} onChange={(event, newValue) => setValue(newValue)} />
    </div>
  );
}

export default App;
