import React, { useEffect } from 'react';
import './BottomSheet.css';

const BottomSheet = ({ isOpen, toggleBottomSheet, setView }) => {
  useEffect(() => {
    const bottomSheetElement = document.querySelector('.bottom-sheet');
    if (!isOpen) {
      bottomSheetElement.style.bottom = '78px';
    } else {
      bottomSheetElement.style.bottom = '56px';
    }
  }, [isOpen]);

  const handleButtonClick = (coordinates) => {
    setView(coordinates, 11.9); // Call setView with coordinates and zoom level
  };

  return (
    <div className={`bottom-sheet ${isOpen ? 'open' : 'closed'}`} onClick={toggleBottomSheet}>
      <div className="bottom-sheet-handle"></div>
      <div className="tap-to-open">Special Stages</div>
      <div className="bottom-sheet-content" onClick={(e) => e.stopPropagation()}>
        <button onClick={() => handleButtonClick([-6.252454329096224, 54.25032857389985])}>SS 1 & 6</button>
        <button onClick={() => handleButtonClick([-6.246034081306391, 54.38299313895908])}>SS 2 & 7</button>
        <button onClick={() => handleButtonClick([-6.377405350921265, 54.23820786355694])}>SS 3 & 8</button>
        <button onClick={() => handleButtonClick([-6.417585588231702, 54.266892998843474])}>SS 4 & 9</button>
        <button onClick={() => handleButtonClick([-6.4124407592613295, 54.32794613697277])}>SS 5 & 10</button>
      </div>
    </div>
  );
};

export default BottomSheet;
