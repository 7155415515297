import React, { useEffect } from 'react';
import './SafetyModal.css'; // Assuming you have a CSS file for styling the modal

const images = [
  `${process.env.PUBLIC_URL}/assets/srsr1.jpg`,
  `${process.env.PUBLIC_URL}/assets/srsr2.jpg`,
  `${process.env.PUBLIC_URL}/assets/srsr3.jpg`
];

const SafetyModal = ({ showModal, setShowModal }) => {
  useEffect(() => {
    if (showModal) {
      const modalsShown = localStorage.getItem('modalsShown') || 0;
      localStorage.setItem('modalsShown', parseInt(modalsShown) + 1);
    }
  }, [showModal]);

  if (!showModal) {
    return null;
  }

  const randomImage = images[Math.floor(Math.random() * images.length)];

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <img src={randomImage} alt="Safety" style={{ width: '100%', opacity: 0.8 }} />
        <button onClick={() => setShowModal(false)} className="close-button">Close Safety Message</button>
      </div>
    </div>
  );
};

export default SafetyModal;
