import React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MapIcon from '@mui/icons-material/Map';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ScheduleIcon from '@mui/icons-material/Schedule';

function BottomNav({ value, onChange }) {
  return (
    <BottomNavigation value={value} onChange={onChange} className="bottom-nav">
      <BottomNavigationAction label="Home" value="home" icon={<HomeIcon />} />
      <BottomNavigationAction label="Maps" value="map" icon={<MapIcon />} />
      <BottomNavigationAction label="Programme" value="pdf" icon={<MenuBookIcon />} />
      <BottomNavigationAction label="Schedule" value="schedule" icon={<ScheduleIcon />} />
      <BottomNavigationAction label="Entry List" value="web" icon={<ListAltIcon />} />
    </BottomNavigation>
  );
}

export default BottomNav;
